import Image from 'next/image'
import Link from 'next/link'
import PageNotFound from '@assets/page_not_found.png'
import { Button } from 'semantic-ui-react'
import styles from './style.module.css'
import SEOTags from '@components/SEOTags'

export default function Custom404() {
    return (
        <div className={styles.container}>
            <SEOTags title="LifeShack | Page Not Found" description='404: Page Not Found' noFollow/>
            <Image src={PageNotFound} alt="page not found" draggable={false} />
            <div className={styles.textWrapper}>
                The requested page was not found
                
                <div className={styles.goHome}>
                    <Link href={"/"} passHref>
                        <Button color="blue" basic>Go Home</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}