import Head from "next/head"

export default function SEOTags({
    title = "LifeShack | AI-Powered Job Search",
    description = "Discover the future of job searching. Our AI matches you with the best remote and hybrid job opportunities and submits applications on your behalf. Save time and get hired faster.",
    url = "https://www.lifeshack.com",
    type = "website",
    image = "https://production-appsyncstack-companyimages307b450c-17ekd4vq0gwy6.s3.us-west-2.amazonaws.com/lifeshack_opengraph.png",
    noFollow = false,
}) {
    return (
        <Head>
            <title>{title}</title>
            <meta
                name="title"
                content={title}
            />
            <meta
                name="description"
                content={description}
            />
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta
                property="og:title"
                content={title}
            />
            <meta
                property="og:description"
                content={description}
            />
            <meta
                property="og:image"
                content={image}
            />
            {
                noFollow && (
                    <meta
                        name="robots"
                        content="noindex, nofollow"
                    />
                )
            }
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Head>
    )
}